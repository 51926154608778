import React from "react";
import "./Header.css";

const Header = () => {
  const headerStyle = {
    backgroundColor: "rgb(0, 31, 61)",
    color: "white",
    padding: "1rem 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    borderBottom: "2px solid white",
  };

  const logoContainerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "0.75rem",
  };

  const logoStyle = {
    width: "2.5rem",
    height: "2.5rem",
    border: "2px solid rgb(255 255 255 / 1)",
    borderRadius: "0.5rem",
    backgroundColor: "rgb(255 255 255 / 1)",
    objectFit: "cover",
  };

  const textStyle = {
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "2rem",
    fontFamily: "'Cormorant Garamond', serif",
    margin: 0,
  };

  const whatsappButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '0.5rem 1rem',
    backgroundColor: '#25D366',
    color: 'white',
    borderRadius: '0.5rem',
    textDecoration: 'none',
    fontWeight: 500,
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    // Add media query styles using @media in your CSS file
    
  };
  

  // Function to handle WhatsApp redirect
  const handleWhatsAppClick = () => {
    // Format the phone number (remove spaces and add country code if needed)
    const phoneNumber = "917795736047";
    // Create WhatsApp URL
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    // Open in new tab
    window.open(whatsappUrl, "_blank");
  };

  return (
    <header style={headerStyle}>
      <div style={logoContainerStyle}>
        <img
          src="https://winlooks.netlify.app/assets/logo-BD_ZnXDL.jpg"
          alt="WINLOOKS Logo"
          style={logoStyle}
        />
        <h1 style={textStyle}>WINLOOKS</h1>
      </div>
      <button onClick={handleWhatsAppClick} style={whatsappButtonStyle}>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp"
          style={{ width: "20px", height: "20px" }}
        />
        WhatsApp Us
      </button>
    </header>
  );
};

export default Header;
